<template>
  <div class="container-fluid px-0">
    <div class="row py-4" style="max-width: 1150px; margin: 0 auto">
      <div class="col-12 col-md-6 mb-4">
        <ul class="list-group">
          <li class="list-group-item" v-if="products.length == 0">
            <div class="row">
              <div class="col-12">
                <h4 class="text-center py-5">Vacio</h4>
              </div>
            </div>
          </li>
          <li
            class="list-group-item list-group-item-action click"
            v-for="(p, i) in products"
            :key="`p-${i}`"
          >
            <div class="row">
              <div class="col-12 col-sm-4">
                <img
                  :src="getImage(p.image)"
                  alt=""
                  class="img-fluid rounded mb-3"
                />
              </div>
              <div class="col">
                <div class="row no-gutters">
                  <div class="col-8">
                    <span class="font-weight-bold d-block">
                      {{ p.qty }} x
                      <span class="text-uppercase">{{ p.name }}</span>
                    </span>
                  </div>
                  <div class="col-4 text-right h-50">
                    <span class="font-weight-bold">
                      ${{ formatNumber(p.total) }}
                    </span>
                  </div>
                  <div class="col">
                    <small class="text-muted mr-3"
                      >VALOR: ${{ formatNumber(p.value) }}</small
                    >
                    <small class="text-muted mr-3" v-if="p.extra"
                      >EXTRAS: ${{ formatNumber(p.extra) }}</small
                    >
                    <small class="text-muted" v-if="p.discount"
                      >DCTO:-${{ formatNumber(p.discount) }}</small
                    >
                  </div>
                  <div class="col col-md-3 text-right"></div>
                </div>
                <!-- <router-link class="text-dark" :to="`/${info.webName}/p/${p._id}`"> -->

                <!-- </router-link> -->
                <div class="col-12" v-if="p.details">
                  <small class="font-weight-bold">Notas: </small
                  ><small>{{ p.details }}</small>
                </div>

                <div class="d-block mt-2" v-if="p.options.length > 0">
                  <small class="d-block">OPCIONES</small>
                  <div class="w-100 border-bottom py-1 ml-auto"></div>
                  <div
                    class="row"
                    v-for="(op, i) in p.options"
                    :key="`op-${i}`"
                  >
                    <div class="col-12">
                      <small class="font-weight-bold text-uppercase">
                        {{ op.name }}:
                      </small>
                    </div>
                    <div class="col-12">
                      <div class="" v-if="op.multiple">
                        <small
                          class="d-block"
                          v-for="(s, i) in op.selected"
                          :key="`se-${i}`"
                          >{{ s.name }}
                          <span class="text-danger" v-if="s.value"
                            >- ${{ s.value }}</span
                          ></small
                        >
                      </div>
                      <small class="" v-else-if="op.type == 'number'">
                        {{ op.selected }}
                      </small>
                      <small class="" v-else>
                        {{ op.selected.name }}
                        <span class="text-danger" v-if="op.selected.value"
                          >- ${{ op.selected.value }}</span
                        >
                      </small>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <small
                  class="btn-link text-danger click"
                  @click="removeProduct(p)"
                >
                  Quitar
                </small>
              </div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="row">
              <div class="col text-right">
                <strong>SUBTOTAL</strong>
              </div>
              <div class="col-3 text-right">
                <strong>${{ formatNumber(subtotal) }}</strong>
              </div>
            </div>
          </li>
          <li class="list-group-item" v-if="type == 'Domicilio'">
            <div class="row">
              <div class="col text-right">
                <strong>ENVIO</strong>
              </div>
              <div class="col-3 text-right">
                <strong>${{ formatNumber(deliveryValue) }}</strong>
              </div>
            </div>
          </li>
          <li class="list-group-item list-group-item-dark" v-if="coupon._id">
            <div class="row">
              <div class="col text-right">
                <strong>CUPON ({{ coupon.code }})</strong>
              </div>
              <div class="col-3 text-right text-dark">
                <strong>-${{ formatNumber(cuponTotal) }}</strong>
              </div>
            </div>
          </li>
          <li class="list-group-item list-group-item-dark">
            <div class="row">
              <div class="col text-right">
                <strong>TOTAL</strong>
              </div>
              <div class="col-3 text-right">
                <strong>${{ formatNumber(total - cuponTotal) }}</strong>
              </div>
            </div>
          </li>
        </ul>
        <div class="mt-3 d-none1 coupon-item" v-if="settings.couponEnabled">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <small>CUPON DE DESCUENTO</small>
              </span>
            </div>
            <input
              type="text"
              class="form-control font-weight-bold"
              :disabled="cuponDisabled"
              ref="couponInput"
              v-model="couponCode"
              @input="couponCode = $event.target.value.toUpperCase()"
              placeholder="(Opcional)"
              @keyup.enter="applyCoupon()"
            />
            <button
              class="btn btn-outline-primary"
              :disabled="cuponDisabled"
              @click="applyCoupon()"
            >
              <b-icon icon="arrow-right" />
            </button>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <ul class="list-group">
          <li class="list-group-item">
            <div class="row align-items-center">
              <div class="col">
                <strong>Tipo de pedido</strong>
                <div class="w-100"></div>
                <small class="text-muted">Seleccione</small>
                <small
                  class="d-block alert alert-danger py-2"
                  v-if="total < settings.minDelivery"
                >
                  Reparto a domicilio minimo desde ${{
                    formatNumber(settings.minDelivery)
                  }}
                </small>
                <div class="btn-group w-100">
                  <button
                    class="btn"
                    v-if="settings.showPickup"
                    @click="type = 'Retiro'"
                    :disabled="!pickupEnabled"
                    :class="{
                      'btn-primary': type == 'Retiro',
                      'btn-outline-primary': type != 'Retiro',
                    }"
                  >
                    RETIRO
                    <span class="d-block" v-if="!pickupEnabled"
                      >(No disponible)</span
                    >
                    <small
                      class="d-block font-italic"
                      v-if="pickupEnabled && settings.pickupWaitTime"
                      >{{ settings.pickupWaitTime }} min aprox.</small
                    >
                  </button>
                  <button
                    class="btn"
                    @click="type = 'Domicilio'"
                    v-if="settings.showDelivery"
                    :disabled="!deliveryEnabled || total < settings.minDelivery"
                    :class="{
                      'btn-primary': type == 'Domicilio',
                      'btn-outline-primary': type != 'Domicilio',
                    }"
                  >
                    DOMICILIO
                    <span class="d-block text-danger" v-if="!deliveryEnabled"
                      >(No disponible)</span
                    >
                    <small
                      class="d-block font-italic"
                      v-if="deliveryEnabled && settings.deliveryWaitTime"
                      >{{ settings.deliveryWaitTime }} min aprox.</small
                    >
                  </button>

                  <button
                    class="btn"
                    @click="type = 'Envio'"
                    v-if="settings.showShipping"
                    :disabled="!shippingEnabled || total < settings.minDelivery"
                    :class="{
                      'btn-primary': type == 'Envio',
                      'btn-outline-primary': type != 'Envio',
                    }"
                  >
                    ENVIO
                    <div class="w-100"></div>
                    <small>POR PAGAR</small>
                    <span class="d-block text-danger" v-if="!shippingEnabled"
                      >(No disponible)</span
                    >
                  </button>
                </div>

                <div class="w-100"></div>
                <!-- schedule ticekt to date -->

                <div
                  class="row mt-2"
                  v-if="type != '' && settings.showSchedule"
                >
                  <div class="col-6">
                    <div class="form-group d-none1">
                      <label for="date" class="font-weight-bold"
                        >Agendar para</label
                      >
                      <select
                        class="form-control"
                        v-model="date"
                        :disabled="!settings.scheduleEnabled"
                      >
                        <option
                          :value="day.date"
                          v-for="day in days"
                          :key="`day-${day.n}`"
                        >
                          {{ day.text }}
                        </option>
                        <!-- <option value="0">
                          Hoy, {{ $moment().format("dddd DD [de] MMMM") }}
                        </option>
                        <option value="1">
                          Mañana,
                          {{
                            $moment().add(1, "day").format("dddd DD [de] MMMM")
                          }}
                        </option>
                        <option value="2">
                          {{
                            $moment().add(2, "day").format("dddd DD [de] MMMM")
                          }}
                        </option> -->
                      </select>
                    </div>
                  </div>
                  <div class="col">
                    <label for="date" class="font-weight-bold">Hora</label>
                    <select
                      class="form-control"
                      v-model="time"
                      :class="{
                        'is-invalid': time == 'null',
                        'is-valid': time != 'null',
                      }"
                    >
                      <option :value="-1">Lo antes posible</option>
                      <option :value="i" v-for="i in hours" :key="`hour-${i}`">
                        {{ $moment().hour(i).minutes(0).format("HH:mm") }}
                      </option>
                    </select>
                  </div>
                  <div class="col-12" v-if="date != 1">
                    <div class="alert alert-info mb-0">
                      <small class="font-weight-bold"
                        >Su pedido quedará a confirmacion del local. Se enviará
                        confirmacion al correo</small
                      >
                    </div>
                  </div>
                </div>

                <select
                  name=""
                  v-model="type"
                  class="form-control d-none"
                  :class="{
                    'is-invalid':
                      (type == 'Retiro' && !pickupEnabled) ||
                      (type == 'Domicilio' && !deliveryEnabled),
                    'is-valid':
                      (type != 'Retiro' && !pickupEnabled) ||
                      (type != 'Domicilio' && !deliveryEnabled),
                  }"
                >
                  <option value="Retiro" :disabled="!pickupEnabled">
                    Retiro en local
                    <span v-if="!pickupEnabled">(No disponible)</span>
                  </option>
                  <option
                    value="Domicilio"
                    :disabled="!deliveryEnabled || total < settings.minDelivery"
                  >
                    Reparto a domicilio
                    <span v-if="!deliveryEnabled">(No disponible)</span>
                  </option>
                </select>
              </div>
            </div>
          </li>
          <li class="list-group-item" v-if="type == 'Domicilio'">
            <div class="row">
              <div class="col-12">
                <div class="alert alert-info font-weight-bold text-uppercase">
                  Detalles sobre la direccion (block, depto, condominio, etc) al
                  final del pedido
                </div>
              </div>
              <div class="col">
                <strong>Direccion</strong>
                <div class="w-100"></div>
                <!-- <button class="btn btn-primary" @click="useLocation()">
                  USAR MI UBICACION
                </button> -->
                <gmap-autocomplete
                  :value="address"
                  ref="addressInput"
                  :componentRestrictions="{ country: 'CL' }"
                  placeholder="Ej: Avenida 123, Comuna"
                  @place_changed="getAddressData"
                  class="form-control"
                  :select-first-on-enter="true"
                >
                </gmap-autocomplete>
                <div class="w-100"></div>
                <input
                  type="text"
                  v-model="address"
                  class="form-control d-none"
                  :class="{
                    'is-invalid': address.length < 4,
                    'is-valid': address.length >= 4,
                  }"
                  @blur="searchAddress()"
                />
                <!-- <button class="btn btn-outline-success" :disabled="!zone.name" @click="searchAddress()">Buscar</button> -->
                <small class="text-muted"> Calle y numero </small>
              </div>
              <div class="col-5 d-none">
                <strong>Comuna</strong>
                <div class="w-100"></div>
                <select
                  v-model="comunne"
                  @change="searchAddress()"
                  class="form-control"
                  :class="{
                    'is-invalid': !comunne._id,
                    'is-valid': comunne.name,
                  }"
                >
                  <option :value="{}" disabled>Seleccione</option>
                  <option
                    class="text-uppercase"
                    :value="z"
                    :key="i"
                    v-for="(z, i) in comunnes"
                  >
                    {{ z.name }}
                  </option>
                </select>
                <small class="text-muted"> Para calcular el envio </small>
              </div>
              <div
                class="col-12"
                :class="{
                  'd-none': type != 'Domicilio',
                }"
              >
                <strong>Sector encontrado</strong>
                <div class="w-100"></div>
                <select
                  v-model="zone"
                  disabled
                  class="form-control"
                  :class="{
                    'is-invalid': !zone._id,
                    'is-valid': zone.name,
                  }"
                >
                  <option :value="{}" disabled>- - - - - - -</option>
                  <option :value="z" :key="i" v-for="(z, i) in zones">
                    {{ z.name }}
                    <span v-if="z.value">- ${{ formatNumber(z.value) }}</span>
                  </option>
                </select>
                <small class="text-muted"> Ubicacion aproximada </small>
              </div>
            </div>
            <div
              class="row"
              v-if="center"
              :class="{
                'd-none': type != 'Domicilio',
              }"
            >
              <gmap-map
                :center="center"
                :options="{
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                }"
                :zoom="zoom"
                style="width: 100%; height: 250px"
              >
                <GmapMarker :position="center" />
                <component
                  :is="path.type == 'zone' ? 'gmap-polygon' : 'gmap-circle'"
                  v-for="(path, i) in zones"
                  :key="`path-${i}`"
                  :paths="path.paths"
                  :center="path.center"
                  :radius="path.radius"
                  :options="{
                    strokeColor: path.color,
                  }"
                />
              </gmap-map>
            </div>
          </li>
          <li class="list-group-item" v-if="type == 'Envio'">
            <div class="row">
              <div class="col-12">
                <div class="alert alert-info font-weight-bold text-uppercase">
                  Detalles sobre la direccion (block, depto, condominio, etc) al
                  final del pedido
                </div>
              </div>
              <div class="col">
                <strong>Direccion</strong>
                <div class="w-100"></div>
                <!-- <button class="btn btn-primary" @click="useLocation()">
                  USAR MI UBICACION
                </button> -->
                <!-- <gmap-autocomplete
                  :value="address"
                  ref="addressInput"
                  :componentRestrictions="{ country: 'CL' }"
                  placeholder="Ej: Avenida 123, Comuna"
                  @place_changed="getAddressData"
                  class="form-control"
                  :select-first-on-enter="true"
                >
                </gmap-autocomplete> -->
                <input
                  type="text"
                  v-model="address"
                  placeholder="Calle 123"
                  class="form-control d-none1"
                  :class="{
                    'is-invalid': address.length < 4,
                    'is-valid': address.length >= 4,
                  }"
                />

                <div class="w-100 py-2"></div>

                <strong>Comuna</strong>
                <div class="w-100"></div>
                <input
                  type="text"
                  placeholder="Ingrese una comuna"
                  v-model="commune"
                  class="form-control d-none1"
                  :class="{
                    'is-invalid': commune.length < 4,
                    'is-valid': commune.length >= 4,
                  }"
                />
                <!-- <select class="form-control">
                  <option
                    value="1"
                    v-for="commune in communes"
                    :key="commune._id"
                  >
                    {{ commune.name }}
                  </option>
                </select> -->
              </div>
              <div class="col-5 d-none">
                <strong>Comuna</strong>
                <div class="w-100"></div>
                <select
                  v-model="comunne"
                  @change="searchAddress()"
                  class="form-control"
                  :class="{
                    'is-invalid': !comunne._id,
                    'is-valid': comunne.name,
                  }"
                >
                  <option :value="{}" disabled>Seleccione</option>
                  <option
                    class="text-uppercase"
                    :value="z"
                    :key="i"
                    v-for="(z, i) in comunnes"
                  >
                    {{ z.name }}
                  </option>
                </select>
                <small class="text-muted"> Para calcular el envio </small>
              </div>

              <div
                class="col-12"
                :class="{
                  'd-none': type != 'Domicilio',
                }"
              >
                <strong>Sector encontrado</strong>
                <div class="w-100"></div>
                <select
                  v-model="zone"
                  disabled
                  class="form-control"
                  :class="{
                    'is-invalid': !zone._id,
                    'is-valid': zone.name,
                  }"
                >
                  <option :value="{}" disabled>- - - - - - -</option>
                  <option :value="z" :key="i" v-for="(z, i) in zones">
                    {{ z.name }}
                    <span v-if="z.value">- ${{ formatNumber(z.value) }}</span>
                  </option>
                </select>
                <small class="text-muted"> Ubicacion aproximada </small>
              </div>
            </div>
            <div
              class="row"
              v-if="center"
              :class="{
                'd-none': type != 'Domicilio',
              }"
            >
              <gmap-map
                :center="center"
                :options="{
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                }"
                :zoom="zoom"
                style="width: 100%; height: 250px"
              >
                <GmapMarker :position="center" />
                <component
                  :is="path.type == 'zone' ? 'gmap-polygon' : 'gmap-circle'"
                  v-for="(path, i) in zones"
                  :key="`path-${i}`"
                  :paths="path.paths"
                  :center="path.center"
                  :radius="path.radius"
                  :options="{
                    strokeColor: path.color,
                  }"
                />
              </gmap-map>
            </div>
          </li>
          <li class="list-group-item" v-if="type == 'Envio'">
            <div class="row">
              <div class="col">
                <strong>Rut</strong>
                <div class="w-100"></div>
                <input
                  type="text"
                  v-model="rut"
                  class="form-control"
                  :class="{
                    'is-invalid': rut.length < 7,
                    'is-valid': rut.length >= 7,
                  }"
                  placeholder="Ingresa tu rut. Ej: 12345678-9"
                />
              </div>
            </div>
          </li>
          <li class="list-group-item" v-if="type != ''">
            <div class="row">
              <div class="col">
                <strong>Tu Nombre</strong>
                <div class="w-100"></div>
                <input
                  type="text"
                  v-model="clientName"
                  :disabled="isLogged()"
                  class="form-control"
                  :class="{
                    'is-invalid': clientName.length < 3,
                    'is-valid': clientName.length >= 3,
                  }"
                  placeholder="Ingresa tu nombre"
                />
              </div>
            </div>
          </li>
          <li class="list-group-item" v-if="type != ''">
            <div class="row">
              <div class="col">
                <strong>Telefono</strong>
                <div class="w-100"></div>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">+56</span>
                  </div>
                  <input
                    type="tel"
                    :disabled="isLogged()"
                    :class="{
                      'is-invalid':
                        phone.length != 9 || !phone.match(/^[0-9]+$/),
                      'is-valid': phone.length == 9 && phone.match(/^[0-9]+$/),
                    }"
                    v-model.trim="phone"
                    class="form-control"
                    placeholder="987654321"
                  />
                </div>
                <small class="text-danger" v-if="!phone.match(/^[0-9]+$/)">
                  Ingrese un telefono valido
                </small>
                <small class="text-muted">Necesario para contacto</small>
              </div>
            </div>
          </li>
          <li class="list-group-item" v-if="type != ''">
            <div class="row">
              <div class="col">
                <strong>Correo</strong>
                <div class="w-100"></div>
                <input
                  type="email"
                  v-model="email"
                  class="form-control"
                  autocapitalize="off"
                  :disabled="isLogged()"
                  :class="{
                    'is-invalid': emailIsValid,
                    'is-valid': !emailIsValid,
                  }"
                  placeholder="Ej: mi@correo.cl"
                  @change="email = $event.target.value.toLowerCase()"
                />
                <small class="text-muted">
                  Te enviaremos actualizaciones de tu pedido
                </small>
              </div>
            </div>
          </li>
          <li class="list-group-item" v-if="type != ''">
            <div class="row">
              <div class="col">
                <strong>Medio de pago</strong>
                <div class="w-100"></div>
                <select
                  v-model="payment"
                  class="form-control"
                  :class="{
                    'is-invalid': payment == '',
                    'is-valid': payment != '',
                  }"
                >
                  <option value="" disabled>Seleccione</option>
                  <option
                    v-if="settings.flowEnabled"
                    :value="{ name: 'Webpay' }"
                  >
                    Pago en linea - WebPay
                  </option>
                  <option
                    v-if="settings.mercadoPagoEnabled"
                    :value="{ name: 'MercadoPago' }"
                  >
                    Pago en linea - MercadoPago
                  </option>
                  <option
                    :disabled="!p.enabled"
                    :value="p"
                    v-for="(p, i) in payments"
                    :key="i"
                  >
                    {{ p.name }}
                  </option>
                </select>
                <div
                  class="alert alert-info mt-2"
                  v-if="!payment.showDetailsOnConfirm && payment.details"
                >
                  <div v-html="paymentDetails"></div>
                </div>
              </div>
            </div>
          </li>
          <li class="list-group-item" v-if="type != '' && settings.tipEnabled">
            <div class="row">
              <div class="col">
                <strong>Propina (Opcional)</strong>
                <div class="w-100"></div>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">$</span>
                  </div>
                  <input
                    type="number"
                    v-model.number="tip"
                    class="form-control"
                    :class="{
                      'is-invalid': tip < 0,
                      'is-valid': tip >= 0,
                    }"
                    placeholder="0"
                  />
                </div>
                <div class="btn-group btn-block">
                  <button
                    class="btn btn-outline-primary btn-sm"
                    @click="setTip(500)"
                  >
                    $500
                  </button>
                  <button
                    class="btn btn-outline-primary btn-sm"
                    @click="setTip(1000)"
                  >
                    $1.000
                  </button>
                  <button
                    class="btn btn-outline-primary btn-sm"
                    @click="setTip(2000)"
                  >
                    $2.000
                  </button>
                  <button
                    class="btn btn-outline-primary btn-sm"
                    @click="recomendedTip()"
                  >
                    10%
                  </button>
                </div>
              </div>
            </div>
          </li>
          <li class="list-group-item" v-if="payment.needAmount">
            <div class="row">
              <div class="col">
                <strong>Cantidad con la pagas</strong>
                <div class="w-100"></div>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">$</span>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    v-model.number="amount"
                    ref="amountInput"
                    :class="{
                      'is-invalid': amount + tip < total,
                      'is-valid': amount + tip >= total,
                    }"
                    @focus="$event.target.select()"
                  />
                  <div class="w-100"></div>
                  <small class="text-muted">NECESARIO PARA VUELTO</small>
                  <div class="w-100"></div>
                  <div class="btn-group btn-block">
                    <button
                      class="btn btn-outline-primary btn-sm"
                      @click="setTotal()"
                    >
                      CON LO JUSTO
                    </button>
                    <button
                      class="btn btn-outline-primary btn-sm"
                      @click="amount = 10000"
                    >
                      $10.000
                    </button>
                    <button
                      class="btn btn-outline-primary btn-sm"
                      @click="amount = 15000"
                    >
                      $15.000
                    </button>
                    <button
                      class="btn btn-outline-primary btn-sm"
                      @click="amount = 20000"
                    >
                      $20.000
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="list-group-item" v-if="type != ''">
            <div class="row">
              <div class="col">
                <strong>Notas adicionales</strong>
              </div>
              <div class="col-12 mt-2">
                <textarea
                  placeholder="Opcional"
                  v-model="notes"
                  rows="3"
                  class="form-control"
                ></textarea>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="col-12 mt-4 mb-5">
        <div class="text-center">
          <span class="d-block mt-2">
            Al utilizar el sitio aceptas los
            <router-link class="font-weight-bold" :to="getLink('tos')"
              >terminos y condiciones</router-link
            >
          </span>
          <button
            class="btn btn-success btn-block btn-lg py-3"
            @click="confirmTicket()"
            :disabled="!ticketState"
            v-if="settings.status != 'offline'"
          >
            ENVIAR PEDIDO
          </button>
        </div>
      </div>
    </div>

    <b-modal
      ref="modal"
      title="Confirmar Pedido"
      cancel-title="Volver"
      ok-title="Confirmar y enviar"
      ok-variant="success"
      @ok="sendTicket()"
    >
      <div class="row">
        <div class="col-12 d-none">
          <div class="alert alert-info">
            Confirma tu pedido antes de ser enviado
          </div>
        </div>
        <div class="col-12 text-center">
          <h4>
            Hola <span class="text-uppercase">{{ clientName }}</span>
          </h4>
          <h4>Estas ordenando para {{ type }}</h4>
          <p>
            Te contactaremos al <strong>{{ phone }}</strong> de ser necesario
          </p>
          <div v-if="type == 'Retiro'">
            Una vez confirmado, podras retirar tu pedido en:
            <div class="w-100"></div>
            <strong>{{ info.address }} - {{ info.commune }}</strong>
            <button
              class="btn btn-outline-info btn-block mt-2"
              @click="openAddres()"
            >
              VER EN MAPS
            </button>
          </div>
          <div v-if="type == 'Domicilio'">
            tu pedido sera enviado a
            <strong>{{ address }}</strong>
          </div>

          <div class="w-100 py-2"></div>

          <div v-if="notes">
            <small>NOTAS DEL PEDIDO</small>
            <div class="w-100"></div>
            <span class="font-italic">{{ notes }}</span>
          </div>

          <div class="w-100 py-3"></div>

          <div class="alert alert-info" v-if="date != 0">
            Tu pedido fue programado para el dia
            <br />
            <strong class="">{{
              $moment().add(date, "days").format("dddd DD [de] MMMM")
            }}</strong>

            <span v-if="time == 'null'">, lo antes posible</span>
            <span v-else> a las {{ time }}:00</span>
          </div>

          <h5>Pagaras con {{ payment && payment.name }}</h5>
          <h4>
            Total del pedido: ${{ formatNumber(total + tip - cuponTotal) }}
          </h4>
          <div
            class="alert alert-info mt-4"
            v-if="!payment.showDetailsOnConfirm && payment.details"
          >
            <small class="mb-3 d-block font-weight-bold"
              >INFORMACION PARA EL PAGO</small
            >
            <div v-html="paymentDetails"></div>
          </div>
          <div>
            <div class="alert alert-success mt-4 d-none">
              En la siguiente pantalla tendras informacion en tiempo real de tu
              pedido
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import { getCenter, isPointInPolygon, getDistance } from "geolib";
// import VueGoogleAutocomplete from "vue-google-autocomplete";
//import Slider from "../components/Slider";
const Slider = () =>
  import(/* webpackChunkName: "slider" */ "../components/Slider");

export default {
  components: {
    // VueGoogleAutocomplete,
    Slider,
  },
  data() {
    return {
      tip: 0,
      rut: "",
      commune: "",
      time: -1,
      date: -1,
      type: "",
      payment: "",
      amount: 0,
      clientName: "",
      phone: "",
      email: "",
      notes: "",
      address: "",
      zone: {},
      zoom: 13,
      center: {
        lat: 0,
        lng: 0,
      },
      loading: false,
      comunne: {},
      addressGPS: {},
      page: {},
      couponCode: "",
      coupon: {},
      cuponDisabled: false,
    };
  },
  mounted() {
    document.title = `Pedido - ${this.info.custom_name || this.info.name}`;
    window.scroll(0, 0);

    if(this.date == -1 && this.days.length > 0) {
      this.date = this.days[0].date
    }

    //this.setTotal();

    if (this.$cookies.get("clientName"))
      this.clientName = this.$cookies.get("clientName");
    if (this.$cookies.get("phone")) this.phone = this.$cookies.get("phone");
    if (this.$cookies.get("email")) this.email = this.$cookies.get("email");
    if (this.$cookies.get("address")) {
      //this.address = this.$cookies.get("address") || "";
    }
    if (this.$cookies.get("addressGPS")) {
      this.addressGPS = this.$cookies.get("addressGPS");
    }

    if (
      this.$cookies.get("ticketId") &&
      this.$cookies.get("bid") == this.info._id
    ) {
      // check if ticket created today

      this.$router.push(
        this.getLink(`pedido/${this.$cookies.get("ticketId")}`)
      );
    }
    this.centerMap();

    let param = this.$store.state.isDomain
      ? this.$route.params.name
      : this.$route.params.id;

    let page = this.info.pages.find((p) => {
      if (p.slug == param) return p;
    });
    if (!page) {
      if (this.info.pages && this.info.pages.length >= 1) {
        let [firstPage] = this.info.pages;
        //this.$router.push(this.getLink(firstPage.slug));
      }
      // this.$router.push(`/${this.info.webName}`);
      return false;
    }

    this.$gtag.event("view_cart", {
      event_category: "cart",
      event_label: "User view cart",
      value: 1,
    });
  },
  methods: {
    recomendedTip() {
      this.tip = parseInt(this.total * 0.1)
    },
    setTip(value) {
      this.tip = value;
    },
    setTotal() {
      this.amount = this.total + this.tip;
    },
    useLocation() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        //this.centerMap();
      });
    },
    applyCoupon() {
      if (!this.couponCode)
        return this.$swal({
          title: "Lo sentimos",
          text: "Debes ingresar un código de cupón",
        });
      if (this.products.length == 0)
        return this.$swal({
          title: "Lo sentimos",
          text: "No puedes aplicar un cupón a un pedido vacío",
        });
      this.loading = true;
      this.coupon = {};
      const id = this.$store.state.info.webName;
      console.log("id", id);
      if (!id || id == undefined) return false;

      this.$http
        .post(`${id}/coupon`, { code: this.couponCode, b: this.info._id })
        .then((res) => {
          let coupon = res.data.data;
          //this.ticket.coupon = this.coupon._id;

          if (
            (coupon.min && this.total < coupon.min) ||
            (coupon.max && this.total > coupon.max)
          )
            return this.$swal({
              title: "Lo sentimos",
              text: `El cupón no es válido para este monto \n Min: $${this.formatNumber(
                coupon.min
              )} - Max: $${this.formatNumber(coupon.max)}`,
            });

          if (coupon.only && coupon.only.length > 0) {
            // check if product cart contains coupon only product
            let ids = coupon.only.map((p) => p.code);
            let filter = this.products.filter((p) => !ids.includes(p.code));

            if (filter && filter.length > 0)
              return this.$swal({
                title: "Lo sentimos",
                text: "El cupón no es válido para este pedido\nSolo aplica a los productos seleccionados",
              });
          }

          if (coupon.exclude && coupon.exclude.length > 0) {
            // check if product cart contains coupon only product
            let ids = coupon.exclude.map((p) => p.code);
            let filter = this.products.filter((p) => ids.includes(p.code));

            if (filter && filter.length > 0)
              return this.$swal({
                title: "Lo sentimos",
                text: "El cupón no es válido para este pedido\nNo aplica a los productos seleccionados",
              });
          }

          this.coupon = coupon;
          this.cuponDisabled = true;
        })
        .catch((err) => {
          this.$swal({
            title: "Error",
            text: "Error al aplicar el codigo",
            icon: "error",
          });
          //this.$refs["couponInput"].focus();
        })
        .finally(() => {
          this.loading = false;
        });

      this.$gtag.event("apply_coupon", {
        event_category: "coupon",
        event_label: "User apply coupon",
        value: 1,
      });
    },
    openAddres() {
      this.openInMaps(`${this.info.address} - ${this.info.commune}`);
    },
    confirmTicket() {
      if (this.amount < this.total && this.payment.needAmount) {
        this.$swal({
          title: "Lo sentimos",
          text: "El monto ingresado es menor al total del pedido",
        });
        return false;
      }
      this.$refs["modal"].show();
      // this.sendTicket()
    },
    getAddressData(data) {
      const { geometry, name, vicinity } = data;

      // this.address = `${name}, ${vicinity}`;
      const lat = geometry.location.lat();
      const lng = geometry.location.lng();
      // console.log({ data, lat, lng });

      this.address = `${name || ""}, ${vicinity || ""}`;
      console.log({ data, lat, lng });
      this.addressGPS = { lat, lng };

      let zone = this.zones.find((z) => {
        console.log({ z });
        let paths = z.paths.map((p) => {
          return { latitude: p.lat, longitude: p.lng };
        });

        let isIn = false;

        if (z.component == "banned") {
          isIn = isPointInPolygon({ latitude: lat, longitude: lng }, paths);
          if (isIn) {
            this.zone = {};
            return this.$swal({
              title: "Lo sentimos",
              text: "La direccion ingresada no esta disponible para envios",
            });
          }
        }
        if (z.type == "zone")
          isIn = isPointInPolygon({ latitude: lat, longitude: lng }, paths);
        else {
          console.log("calculating distance");
          let distance = getDistance(
            { latitude: z.center.lat, longitude: z.center.lng },
            { latitude: lat, longitude: lng }
          );
          let km = z.kms.find(
            (km) => km.start <= distance && km.end >= distance
          );
          console.log({ km });
          if (km) isIn = true;
        }

        if (isIn && z.minDelivery && z.minDelivery > this.total) {
          this.zone = {};
          return this.$swal({
            title: "Lo sentimos",
            text: `El monto minimo para envios a esta zona es de $${this.formatNumber(
              z.minDelivery
            )}`,
          });
        }

        // console.log({ name: z.name, isIn });
        // console.table(paths);
        if (isIn) {
          this.zoom = 16;
          this.zone = z;
          this.center = { lat, lng };
          return z;
        }
        // else {
        //   this.$swal({
        //     title: "Lo sentimos",
        //     text: "No se encontro un valor para la distancia",
        //   });
        // }
      });
      if (!zone) {
        //alert('Fuera de la zona de reparto')
        this.$swal({
          title: "Lo sentimos",
          text: "La dirección esta fuera de la zona de reparto",
        });
        // this.center = data.location;
        this.zoom = 16;
        this.center = { lat, lng };
        this.zone = {};
      }
    },
    centerMap() {
      let zones = [];
      this.zones.forEach((zone) => {
        let z = zone.paths.map((z) => ({ latitude: z.lat, longitude: z.lng }));
        zones.push(...z);
      });

      let center = getCenter(zones);

      if (center) this.center = { lat: center.latitude, lng: center.longitude };
    },
    searchAddress() {
      if (this.address.length < 3) return false;
      if (!this.comunne._id) return false;

      this.$http
        .post("cords", {
          address: `${this.address}`,
          zone: this.comunne,
        })
        .then((res) => {
          let data = res.data.data;
          if (data.location) {
            let { lat, lng } = data.location;
            // console.log({ lat, lng });

            let zone = this.zones.find((z) => {
              let paths = z.paths.map((p) => {
                return { latitude: p.lat, longitude: p.lng };
              });
              let isIn = isPointInPolygon(
                { latitude: lat, longitude: lng },
                paths
              );
              console.log({ name: z.name, isIn });
              // console.table(paths);
              if (isIn) {
                if (z.type == "banned") {
                  console.log("banned");
                  return false;
                }
                this.zoom = 16;
                this.zone = z;
                this.center = data.location;
                return z;
              }
            });
            if (!zone) {
              //alert('Fuera de la zona de reparto')
              this.$swal({
                title: "Lo sentimos",
                text: "La dirección esta fuera de la zona de reparto",
              });
              this.center = data.location;
              this.zoom = 13;
              this.zone = {};
            }
          } else {
            this.comunne = {};
            this.$swal({
              title: "Lo sentimos",
              text: "La dirección esta fuera de la zona de reparto",
            });
            this.center = data.location;
            this.zoom = 13;
            this.zone = {};
          }
        });
    },
    sendTicket() {
      let zname = "";
      this.loading = true;
      if (this.type == "Domicilio")
        zname = this.zone ? this.zone.searchName || this.zone.name : ".";

      let data = {
        type: this.type,
        payment: this.payment.name,
        clientName: this.clientName,
        rut: this.rut,
        phone: this.phone,
        email: this.email,
        notes: this.notes,
        address: `${this.address} ${this.commune}`,
        zname: zname,
        products: this.products,
        bid: this.$store.state.info._id,
        amount: this.amount,
        gps: this.center,
        deliveryValue: this.deliveryValue,
        coupon: this.coupon,
        date: this.date,
        time: this.time,
        tip: this.tip,
      };

      try {
        this.$analytics.fbq("track", "Purchase", {
          value: this.total,
        });
        this.$gtag.event("send_ticket", {
          event_category: "ticket",
          event_label: "User send ticket",
          value: 1,
        });
      } catch (error) {
        console.log("Error analytics");
      }

      this.$http
        .post("ticket", data)
        .then((res) => {
          console.log(res);
          this.$store.commit("clearProducts");
          // save ticket id to cookies
          this.$cookies.set("ticketId", res.data.data._id, 60 * 60 * 6);
          this.$cookies.set("bid", this.info._id);
          this.$router.push(this.getLink(`pedido/${res.data.data._id}`));
        })
        .catch((err) => {
          let msg = err.data ? err.data.details : "Error al enviar el pedido";
          this.$swal({
            title: "Error",
            text: msg,
            icon: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    removeProduct(p) {
      let ps = this.products.filter((pr) => pr != p);
      this.$store.commit("setProducts", ps);
      if (this.total < this.settings.minDelivery) {
        this.type = "Retiro";
      }

      this.$gtag.event("remove_product", {
        event_category: "cart",
        event_label: "User remove product from cart",
        value: 1,
      });
    },
    getNextOpenDay() {
      let day = this.$moment().add(this.date, "days").day();
      let days = this.settings.days;
      let nextDay = days.find((d) => d.n > day && d.openAt && d.closeAt);
      if (!nextDay) {
        this.date += 1;
        return this.getNextOpenDay();
      }
      console.log({ nextDay })
      return nextDay;
    },
  },
  computed: {
    days() {
      // const curentDayNumber = this.$moment().day();
      // const nextThreeDaysOfNextWeek = this.settings.days.filter((d) => d.openAt && d.closeAt).slice(0, 3);
      let days = this.settings.days.filter((d) => d.openAt && d.closeAt)  
      let daysplain = days.map((d) => d.n)

      const nextSevenDays = []

      for (let i = 0; i < 7; i++) {
        const dayopen = days[i]

        const day = this.$moment().add(i, 'days')
        const dayNumber = day.day()
        console.log({dayNumber})

        if(daysplain.includes(dayNumber)) {
          nextSevenDays.push({
            n: day.format('d'),
            text: day.format('dddd DD [de] MMMM'),
            date: day.format('YYYY-MM-DD'),
            data: days[i]
          })
        }
        else {
          console.log('dayopen', dayopen)
        }
       
      }
      console.log({ nextSevenDays, days })
      return nextSevenDays
    },
    hours() {
      let currentDay = this.settings.days.find(
        (d) => d.n == this.$moment(this.date).day()
      );

      console.log({ currentDay })

      if(!currentDay) {
        //this.date += 1;
        return [];
      }
      if(!currentDay.openAt || !currentDay.closeAt) {
        let day = this.getNextOpenDay()
        currentDay = day;
        this.date = day.n;
      }
      
      let openAt = parseInt(currentDay ? currentDay.openAt.split(":")[0] : 6);
      let closeAt = parseInt(
        currentDay ? currentDay.closeAt.split(":")[0] : 23
      );

      console.log({ currentDay, openAt, closeAt });

      let hours = [];

      if (this.date == 0) {
        let currentHour = this.$moment().hour() + 2;
        console.log({ currentHour });
        for (let i = currentHour; i < closeAt; i++) {
          hours.push(i);
        }
      } else {
        for (let i = openAt; i < closeAt; i++) {
          hours.push(i);
        }
      }

      if (currentDay && currentDay.openAt == "" && currentDay.closeAt == "") {
        this.date = 0;
        this.time = -1;
        this.$swal({
          title: "Lo sentimos",
          text: "El día seleccionado no tenemos horarios de atención",
        });
        setTimeout(() => {
          this.date += 1;
        }, 800);
      }

      if (this.time < openAt || this.time > closeAt) {
        this.time = -1;
      }

      return hours;
    },
    cuponTotal() {
      let discount = 0;
      let coupon = this.coupon;
      if (coupon && coupon._id) {
        if (coupon.to == "delivery") {
          if (coupon.type == "percent") {
            discount = this.deliveryValue * (coupon.amount / 100);
          } else {
            discount = coupon.amount;
          }

          if (discount > this.deliveryValue) discount = this.deliveryValue;
        } else if (coupon.to == "subtotal") {
          if (coupon.type == "percent") {
            discount = this.subtotal * (coupon.amount / 100);
          } else {
            discount = coupon.amount;
          }

          if (discount > this.subtotal) discount = this.subtotal;
        }
        if (coupon.to == "total") {
          if (coupon.type == "percent") {
            discount = this.total * (coupon.amount / 100);
          } else {
            discount = coupon.amount;
          }

          if (discount > this.total) discount = this.total;
        }
      }
      return discount;
    },
    paymentDetails() {
      return (
        this.payment &&
        this.payment.details
          .replace(/\n/g, "<br>")
          .replace("<a", '<a target="_blank" class="font-weight-bold"')
      );
    },
    shippingEnabled() {
      let { shippingEnabled } = this.info.settings;
      return shippingEnabled;
    },
    pickupEnabled() {
      let { pickupEnabled } = this.info.settings;
      return pickupEnabled;
    },
    deliveryEnabled() {
      let { deliveryEnabled } = this.info.settings;
      return deliveryEnabled;
    },
    deliveryValue() {
      if (this.type == "Retiro") return 0;
      if (this.zone._id) {
        let deliveryValue = this.zone.value;

        if (this.zone.type == "km") {
          let distance = getDistance(
            { latitude: this.zone.center.lat, longitude: this.zone.center.lng },
            { latitude: this.center.lat, longitude: this.center.lng }
          );
          console.log("kms", this.zone.kms);
          let km = this.zone.kms.find(
            (km) => km.start <= distance && km.end >= distance
          );
          console.log({ km });
          if (km) deliveryValue = km.value;
          else this.$swal("Error", "No se encontró zona para la distancia");
          console.log("distance", distance);
        }

        return deliveryValue;
      }
      return 0;
    },
    comunnes() {
      let data = [];
      if (this.info && this.info.settings && this.info.settings.comunnes)
        data = this.info.settings.comunnes;
      return data;
    },
    zones() {
      let data = [];
      if (this.info && this.info.settings && this.info.settings.zones)
        data = this.info.settings.zones;
      return data;
    },
    settings() {
      return this.info && this.info.settings ? this.info.settings : {};
    },
    payments() {
      return this.settings.payments;
    },
    info() {
      return this.$store.state.info;
    },
    ticketState() {
      if (this.loading) return false;
      if (this.status() == "Offline") return false;
      if (
        this.settings.pickupEnabled == false &&
        this.settings.deliveryEnabled == false
      )
        return false;
      if (this.type == "") return false;
      if (this.clientName.length < 3) return false;
      if (this.phone.length != 9) return false;
      if (this.emailIsValid) return false;
      if (this.payment == "") return false;
      if (this.products.length == 0) return false;
      if (!this.zone._id && this.type == "Domicilio") return false;
      return true;
    },
    emailIsValid() {
      const email = this.email;
      var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return !re.test(String(email).toLowerCase());
    },
    subtotal() {
      return this.products.reduce((t, a) => t + a.total, 0);
    },
    total() {
      let total =
        this.products.reduce((t, a) => t + a.total, 0) + this.deliveryValue;

      return total;
    },
    products() {
      return this.$store.state.products;
    },
  },
  watch: {
    payment() {
      if (this.payment.needAmount) {
        setTimeout(() => {
          this.$refs["amountInput"].focus();
        }, 500);
      }
    },
    products() {
      //this.setTotal();
    },
    deliveryValue() {
      this.amount = this.total;
    },
    cuponTotal() {
      this.amount = this.total;
    },
    type() {
      if (this.type == "Domicilio") {
        setTimeout(() => {
          console.log(this.$refs["addressInput"].$el.focus());
          //this.$refs["addressInput"].focus();
          //this.$refs["addressInput"].update(this.$cookies.get("address"));
          //this.$refs['addressInput'].updateCoordinates(this.$cookies.get("addressGPS"));
        }, 1500);
      }
    },
    clientName() {
      this.$cookies.set("clientName", this.clientName);
    },
    phone() {
      this.$cookies.set("phone", this.phone);
    },
    email() {
      this.$cookies.set("email", this.email);
    },
    address() {
      this.$cookies.set("address", this.address);
    },
    addressGPS() {
      this.$cookies.set("addressGPS", this.addressGPS);
    },
  },
};
</script>